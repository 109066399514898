'use strict';

var React = require("react");
var Images = require("../Images/Images.bs.js");
var Environment = require("../Bindings/Environment.bs.js");

function LoginPageLayout(Props) {
  var children = Props.children;
  var clientBanner = Environment.clientBanner(Images.atveroBanner);
  var transluscentBgStyle = {
    backgroundColor: Environment.transluscentBgColour
  };
  var accentColourTextStyle = {
    color: Environment.accentColour
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement("img", {
                      className: "atvero-logo",
                      src: Images.poweredByAtveroLogo
                    })), React.createElement("div", {
                  className: "login-container col-sm-12 container constrained-width",
                  style: transluscentBgStyle
                }, React.createElement("div", {
                      className: "login-text"
                    }, React.createElement("img", {
                          className: "company-banner login-banner",
                          src: clientBanner
                        }), React.createElement("p", {
                          className: "header-text text blue bold",
                          style: accentColourTextStyle
                        }, "CheckPoint")), React.createElement("div", {
                      className: "row align-items-start"
                    }, children)));
}

var make = LoginPageLayout;

exports.make = make;
/* react Not a pure module */
