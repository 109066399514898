'use strict';

var Req = require("./Req.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Types = require("../Types.bs.js");
var Utils = require("../Utils/Utils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function loadFiles(apiUrl, token, sessionKey, progress) {
  Curry._1(progress, /* Loading */1);
  var url = apiUrl + "/list?token=" + token + "&session=" + sessionKey;
  return Req.get(url, (function (responseJson) {
                var goodResponse = Types.files_t_decode(responseJson);
                if (goodResponse.TAG === /* Ok */0) {
                  return Curry._1(progress, {
                              TAG: /* Success */2,
                              _0: Belt_Array.map(goodResponse._0.files, (function (file) {
                                      return {
                                              file: file,
                                              downloadProgress: 0
                                            };
                                    }))
                            });
                } else {
                  return Req.handleDecodeError(responseJson, (function (errors) {
                                var exnResponse = errors.length === 1 ? Utils.getServerExn(Belt_Array.getExn(errors, 0)) : ({
                                      RE_EXN_ID: Types.Exn.Multiple,
                                      _1: Belt_Array.map(errors, Utils.getServerExn)
                                    });
                                return Curry._1(progress, {
                                            TAG: /* Failure */1,
                                            _0: exnResponse
                                          });
                              }), (function (err) {
                                return Curry._1(progress, {
                                            TAG: /* Failure */1,
                                            _0: err
                                          });
                              }));
                }
              }), (function (param) {
                return Curry._1(progress, {
                            TAG: /* Failure */1,
                            _0: {
                              RE_EXN_ID: Types.Exn.RestError,
                              _1: "Failed to load files"
                            }
                          });
              }));
}

function getFileDownloadLink(apiUrl, token, sessionKeyStatus, filename, progress) {
  if (typeof sessionKeyStatus !== "number" && sessionKeyStatus.TAG === /* Success */2) {
    Curry._1(progress, /* Loading */1);
    var encodedFilename = encodeURIComponent(filename);
    var url = apiUrl + "/getfile?file=" + encodedFilename + "&token=" + token + "&session=" + sessionKeyStatus._0;
    return Req.get(url, (function (responseJson) {
                  var goodResponse = Types.download_link_t_decode(responseJson);
                  if (goodResponse.TAG === /* Ok */0) {
                    return Curry._1(progress, {
                                TAG: /* Success */2,
                                _0: goodResponse._0.download_link
                              });
                  } else {
                    return Req.handleDecodeError(responseJson, (function (errors) {
                                  var exnResponse = errors.length === 1 ? Utils.getServerExn(Belt_Array.getExn(errors, 0)) : ({
                                        RE_EXN_ID: Types.Exn.Multiple,
                                        _1: Belt_Array.map(errors, Utils.getServerExn)
                                      });
                                  return Curry._1(progress, {
                                              TAG: /* Failure */1,
                                              _0: exnResponse
                                            });
                                }), (function (err) {
                                  return Curry._1(progress, {
                                              TAG: /* Failure */1,
                                              _0: err
                                            });
                                }));
                  }
                }), (function (param) {
                  return Curry._1(progress, {
                              TAG: /* Failure */1,
                              _0: {
                                RE_EXN_ID: Types.Exn.RestError,
                                _1: "Failed to get download link"
                              }
                            });
                }));
  }
  return function (param) {
    return Curry._1(progress, {
                TAG: /* Failure */1,
                _0: {
                  RE_EXN_ID: Types.Exn.RestError,
                  _1: "Failed to get download link"
                }
              });
  };
}

function startSession(apiUrl, token, progress, userEmailOpt, param) {
  var userEmail = userEmailOpt !== undefined ? Caml_option.valFromOption(userEmailOpt) : undefined;
  Curry._1(progress, /* Loading */1);
  var emailStr = userEmail !== undefined ? "&email=" + userEmail : "";
  var url = apiUrl + "/startsession?token=" + token + emailStr;
  return Req.get(url, (function (responseJson) {
                var goodResponse = Types.challenge_key_t_decode(responseJson);
                if (goodResponse.TAG === /* Ok */0) {
                  return Curry._1(progress, {
                              TAG: /* Success */2,
                              _0: goodResponse._0.challenge_key
                            });
                } else {
                  return Req.handleStartOpenSessionDecodeError(responseJson, (function (errors) {
                                var exnResponse = errors.length === 1 ? Utils.getServerExn(Belt_Array.getExn(errors, 0)) : ({
                                      RE_EXN_ID: Types.Exn.Multiple,
                                      _1: Belt_Array.map(errors, Utils.getServerExn)
                                    });
                                return Curry._1(progress, {
                                            TAG: /* Failure */1,
                                            _0: exnResponse
                                          });
                              }), (function (err) {
                                return Curry._1(progress, {
                                            TAG: /* Failure */1,
                                            _0: err
                                          });
                              }));
                }
              }), (function (param) {
                return Curry._1(progress, {
                            TAG: /* Failure */1,
                            _0: {
                              RE_EXN_ID: Types.Exn.RestError,
                              _1: "Failed to start session"
                            }
                          });
              }));
}

function verifyCode(apiUrl, token, challengeKey, code, progress) {
  Curry._1(progress, /* Loading */1);
  var url = apiUrl + "/validatecode?token=" + token + "&challenge=" + challengeKey + "&code=" + code;
  return Req.get(url, (function (responseJson) {
                var goodResponse = Types.session_key_t_decode(responseJson);
                if (goodResponse.TAG === /* Ok */0) {
                  return Curry._1(progress, {
                              TAG: /* Success */2,
                              _0: goodResponse._0.session_key
                            });
                } else {
                  return Req.handleDecodeError(responseJson, (function (errors) {
                                var exnResponse = errors.length === 1 ? Utils.getServerExn(Belt_Array.getExn(errors, 0)) : ({
                                      RE_EXN_ID: Types.Exn.Multiple,
                                      _1: Belt_Array.map(errors, Utils.getServerExn)
                                    });
                                return Curry._1(progress, {
                                            TAG: /* Failure */1,
                                            _0: exnResponse
                                          });
                              }), (function (err) {
                                return Curry._1(progress, {
                                            TAG: /* Failure */1,
                                            _0: err
                                          });
                              }));
                }
              }), (function (param) {
                return Curry._1(progress, {
                            TAG: /* Failure */1,
                            _0: {
                              RE_EXN_ID: Types.Exn.RestError,
                              _1: "Failed to verify code"
                            }
                          });
              }));
}

function openLinkNewTab(url) {
  return Curry._1(Utils.open_, url);
}

function getZipDownload(apiUrl, token, sessionKeyStatus, progress) {
  if (typeof sessionKeyStatus !== "number" && sessionKeyStatus.TAG === /* Success */2) {
    Curry._1(progress, /* Loading */1);
    var url = apiUrl + "/getzip?token=" + token + "&session=" + sessionKeyStatus._0;
    Curry._1(Utils.open_, url);
    return function (param) {
      
    };
  }
  return function (param) {
    return Curry._1(progress, {
                TAG: /* Failure */1,
                _0: {
                  RE_EXN_ID: Types.Exn.RestError,
                  _1: "Failed to get zip download"
                }
              });
  };
}

exports.loadFiles = loadFiles;
exports.getFileDownloadLink = getFileDownloadLink;
exports.startSession = startSession;
exports.verifyCode = verifyCode;
exports.openLinkNewTab = openLinkNewTab;
exports.getZipDownload = getZipDownload;
/* Utils Not a pure module */
