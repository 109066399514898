'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Environment = require("../Bindings/Environment.bs.js");
var DisplayError = require("../Components/DisplayError.bs.js");
var LoginPageLayout = require("../Components/LoginPageLayout.bs.js");

function CollectEmail(Props) {
  var setUserEmail = Props.setUserEmail;
  var match = React.useState(function () {
        return "";
      });
  var updateUserEmail = match[1];
  var userEmail = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsLoading = match$1[1];
  var formSubmitted = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotSent */0;
      });
  var response = match$2[0];
  var accentColourBgStyle = {
    backgroundColor: Environment.accentColour
  };
  var accentColourTextStyle = {
    color: Environment.accentColour
  };
  React.useEffect((function () {
          if (formSubmitted) {
            Curry._1(setUserEmail, userEmail);
            return ;
          }
          
        }), [formSubmitted]);
  var isLoading = typeof response === "number" ? response !== 0 : false;
  var responseMessageElement;
  if (typeof response === "number") {
    responseMessageElement = null;
  } else {
    switch (response.TAG | 0) {
      case /* Failure */1 :
          responseMessageElement = React.createElement(DisplayError.make, {
                exn: response._0
              });
          break;
      case /* Success */2 :
          responseMessageElement = React.createElement("div", {
                className: "login-error alert alert-primary",
                role: "alert",
                style: accentColourTextStyle
              }, response._0);
          break;
      default:
        responseMessageElement = null;
    }
  }
  var formSubmit = function (e) {
    e.preventDefault();
    return Curry._1(setIsLoading, (function (param) {
                  return true;
                }));
  };
  var emailSubmit = function (e) {
    e.preventDefault();
    return Curry._1(updateUserEmail, e.target.value);
  };
  var submitButtonClick = function (e) {
    e.preventDefault();
    return Curry._1(setIsLoading, (function (param) {
                  return true;
                }));
  };
  var backgroundStyle = {
    backgroundColor: Environment.backgroundColour
  };
  var textStyle = {
    color: Environment.fontColour
  };
  if (formSubmitted) {
    if (isLoading) {
      return React.createElement("div", {
                  className: "login-display row"
                }, React.createElement(LoginPageLayout.make, {
                      children: React.createElement("div", {
                            className: "row pt-3"
                          }, React.createElement("div", {
                                className: "col-xs-12 form-outline "
                              }, React.createElement("div", {
                                    className: "spinner-border ",
                                    role: "status",
                                    style: accentColourTextStyle
                                  })))
                    }));
    } else {
      return React.createElement("div", {
                  className: "login-display row"
                }, React.createElement(LoginPageLayout.make, {
                      children: React.createElement("div", {
                            className: "row pt-3"
                          }, React.createElement("div", {
                                className: "col-xs-12 form-outline "
                              }, responseMessageElement))
                    }));
    }
  }
  var submitBtnDisabled = userEmail.length === 0 || formSubmitted;
  return React.createElement("div", {
              className: "login-display row"
            }, React.createElement(LoginPageLayout.make, {
                  children: React.createElement("div", {
                        className: "row align-items-end login-form",
                        style: backgroundStyle
                      }, React.createElement("form", {
                            onSubmit: formSubmit
                          }, React.createElement("div", {
                                className: "row "
                              }, React.createElement("div", {
                                    className: "col-xs-12 form-outline",
                                    style: textStyle
                                  }, React.createElement("h4", {
                                        style: textStyle
                                      }, "File Exchange Login"), React.createElement("br", undefined), React.createElement("p", {
                                        style: textStyle
                                      }, "For security reasons, please enter the email address this transmittal was sent to. Please note, only listed recipients can access this transmittal."), React.createElement("p", {
                                        className: "small-text",
                                        style: textStyle
                                      }, "If you haven't received a link within a few minutes, please check your spam/junk folder. "))), React.createElement("div", {
                                className: "row pt-3"
                              }, React.createElement("div", {
                                    className: "col-xs-12 form-outline "
                                  }, React.createElement("input", {
                                        className: "input-field custom-form-control",
                                        disabled: formSubmitted,
                                        placeholder: "Please enter your email here",
                                        type: "email",
                                        value: userEmail,
                                        onChange: emailSubmit
                                      }), React.createElement("input", {
                                        className: "btn btn-primary",
                                        style: accentColourBgStyle,
                                        disabled: submitBtnDisabled,
                                        type: "submit",
                                        value: "Next",
                                        onClick: submitButtonClick
                                      })))))
                }));
}

var make = CollectEmail;

exports.make = make;
/* react Not a pure module */
