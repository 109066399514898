'use strict';


function addProgressListener(req, callback) {
  req.addEventListener("progress", callback);
  
}

function addErrorListener(req, callback) {
  req.addEventListener("error", callback);
  
}

exports.addProgressListener = addProgressListener;
exports.addErrorListener = addErrorListener;
/* No side effect */
