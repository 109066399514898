'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Types = require("../Types.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var $$XMLHttpRequest = require("../Bindings/XMLHttpRequest.bs.js");

var Status = {};

function getAbortClosure(request, param) {
  request.abort();
  
}

function get(url, success, failure) {
  var request = new XMLHttpRequest();
  request.addEventListener("load", (function (param) {
          return Curry._1(success, JSON.parse(request.responseText));
        }));
  request.addEventListener("error", (function (param) {
          return Curry._1(failure, undefined);
        }));
  request.open("GET", url);
  request.setRequestHeader("Access-Control-Allow-Headers", "*");
  request.setRequestHeader("Accept", "application/json");
  request.send();
  return function (param) {
    request.abort();
    
  };
}

function handleDecodeError(responseJson, success, failure) {
  var goodResponse = Types.rest_errors_t_decode(responseJson);
  if (goodResponse.TAG === /* Ok */0) {
    return Curry._1(success, goodResponse._0.errors);
  }
  var deccoErr = goodResponse._0;
  console.log("Failed to parse json error: ", deccoErr);
  console.log("reponse", responseJson);
  return Curry._1(failure, {
              RE_EXN_ID: Types.Exn.DeccoDecodeError,
              _1: "An unexpected error occured",
              _2: deccoErr
            });
}

function handleStartOpenSessionDecodeError(responseJson, success, failure) {
  var goodResponse = Types.start_open_session_error_t_decode(responseJson);
  if (goodResponse.TAG === /* Ok */0) {
    return Curry._1(success, Belt_Array.map(goodResponse._0.errors, (function (r) {
                      return r.message;
                    })));
  }
  var deccoErr = goodResponse._0;
  console.log("Failed to parse json error: ", deccoErr);
  return Curry._1(failure, {
              RE_EXN_ID: Types.Exn.DeccoDecodeError,
              _1: "Failed to parse error message response",
              _2: deccoErr
            });
}

function downloadBlob(url, progress) {
  Curry._1(progress, {
        TAG: /* Downloading */0,
        _0: 0
      });
  var request = new XMLHttpRequest();
  request.addEventListener("load", (function (param) {
          var plainResponse = request.response;
          return Curry._1(progress, {
                      TAG: /* Success */2,
                      _0: plainResponse
                    });
        }));
  $$XMLHttpRequest.addErrorListener(request, (function (exn) {
          return Curry._1(progress, {
                      TAG: /* Failure */1,
                      _0: exn
                    });
        }));
  $$XMLHttpRequest.addProgressListener(request, (function (progressEv) {
          var percent = progressEv.loaded / progressEv.total * 100;
          return Curry._1(progress, {
                      TAG: /* Downloading */0,
                      _0: Math.floor(percent)
                    });
        }));
  request.responseType = "blob";
  request.open("GET", url);
  request.setRequestHeader("Access-Control-Allow-Headers", "*");
  request.setRequestHeader("Accept", "*/*");
  request.send();
  return function (param) {
    request.abort();
    
  };
}

exports.Status = Status;
exports.getAbortClosure = getAbortClosure;
exports.get = get;
exports.handleDecodeError = handleDecodeError;
exports.handleStartOpenSessionDecodeError = handleStartOpenSessionDecodeError;
exports.downloadBlob = downloadBlob;
/* No side effect */
