'use strict';

var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

function file_t_encode(v) {
  return Js_dict.fromArray([[
                "name",
                Decco.stringToJson(v.name)
              ]]);
}

function file_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "name"), null));
  if (name.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function files_t_encode(v) {
  return Js_dict.fromArray([[
                "files",
                Decco.arrayToJson(file_t_encode, v.files)
              ]]);
}

function files_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var files = Decco.arrayFromJson(file_t_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "files"), null));
  if (files.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              files: files._0
            }
          };
  }
  var e = files._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".files" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function download_link_t_encode(v) {
  return Js_dict.fromArray([[
                "download_link",
                Decco.stringToJson(v.download_link)
              ]]);
}

function download_link_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var download_link = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "download_link"), null));
  if (download_link.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              download_link: download_link._0
            }
          };
  }
  var e = download_link._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".download_link" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function challenge_key_t_encode(v) {
  return Js_dict.fromArray([[
                "challenge_key",
                Decco.stringToJson(v.challenge_key)
              ]]);
}

function challenge_key_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var challenge_key = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "challenge_key"), null));
  if (challenge_key.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              challenge_key: challenge_key._0
            }
          };
  }
  var e = challenge_key._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".challenge_key" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function session_key_t_encode(v) {
  return Js_dict.fromArray([[
                "session_key",
                Decco.stringToJson(v.session_key)
              ]]);
}

function session_key_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var session_key = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "session_key"), null));
  if (session_key.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              session_key: session_key._0
            }
          };
  }
  var e = session_key._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".session_key" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function open_session_t_encode(v) {
  return Js_dict.fromArray([[
                "status",
                Decco.stringToJson(v.status)
              ]]);
}

function open_session_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var status = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "status"), null));
  if (status.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              status: status._0
            }
          };
  }
  var e = status._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".status" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function rest_errors_t_encode(v) {
  return Js_dict.fromArray([[
                "errors",
                Decco.arrayToJson(Decco.stringToJson, v.errors)
              ]]);
}

function rest_errors_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var errors = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict._0, "errors"), null));
  if (errors.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              errors: errors._0
            }
          };
  }
  var e = errors._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".errors" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var RestError = /* @__PURE__ */Caml_exceptions.create("Types.Exn.RestError");

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Types.Exn.UnknownError");

var DeccoDecodeError = /* @__PURE__ */Caml_exceptions.create("Types.Exn.DeccoDecodeError");

var NoTokenSpecified = /* @__PURE__ */Caml_exceptions.create("Types.Exn.NoTokenSpecified");

var NoSessionSpecified = /* @__PURE__ */Caml_exceptions.create("Types.Exn.NoSessionSpecified");

var NoFileSpecified = /* @__PURE__ */Caml_exceptions.create("Types.Exn.NoFileSpecified");

var NoChallengeSpecified = /* @__PURE__ */Caml_exceptions.create("Types.Exn.NoChallengeSpecified");

var NoCodeSpecified = /* @__PURE__ */Caml_exceptions.create("Types.Exn.NoCodeSpecified");

var InvalidSession = /* @__PURE__ */Caml_exceptions.create("Types.Exn.InvalidSession");

var UnexpectedUrl = /* @__PURE__ */Caml_exceptions.create("Types.Exn.UnexpectedUrl");

var ExpiredToken = /* @__PURE__ */Caml_exceptions.create("Types.Exn.ExpiredToken");

var InvalidToken = /* @__PURE__ */Caml_exceptions.create("Types.Exn.InvalidToken");

var Multiple = /* @__PURE__ */Caml_exceptions.create("Types.Exn.Multiple");

var Exn = {
  RestError: RestError,
  UnknownError: UnknownError,
  DeccoDecodeError: DeccoDecodeError,
  NoTokenSpecified: NoTokenSpecified,
  NoSessionSpecified: NoSessionSpecified,
  NoFileSpecified: NoFileSpecified,
  NoChallengeSpecified: NoChallengeSpecified,
  NoCodeSpecified: NoCodeSpecified,
  InvalidSession: InvalidSession,
  UnexpectedUrl: UnexpectedUrl,
  ExpiredToken: ExpiredToken,
  InvalidToken: InvalidToken,
  Multiple: Multiple,
  noTokenSpecifiedConst: "No Token Specified",
  noSessionSpecifiedConst: "No Session Specified",
  noFileSpecifiedConst: "No File Specified",
  noChallengeSpecifiedConst: "No challenge Specified",
  noCodeSpecifiedConst: "No code Specified",
  invalidSessionConst: "Invalid session - not found",
  expiredTokenConst: "Token has expired",
  invalidTokenConst: "Invalid token specified"
};

function start_open_session_error_reason_t_encode(v) {
  return Js_dict.fromArray([[
                "Message",
                Decco.stringToJson(v.message)
              ]]);
}

function start_open_session_error_reason_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var message = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "Message"), null));
  if (message.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".Message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function start_open_session_error_t_encode(v) {
  return Js_dict.fromArray([
              [
                "IsFailed",
                Decco.boolToJson(v.isFailed)
              ],
              [
                "IsSuccess",
                Decco.boolToJson(v.isSuccess)
              ],
              [
                "Errors",
                Decco.arrayToJson(start_open_session_error_reason_t_encode, v.errors)
              ]
            ]);
}

function start_open_session_error_t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var isFailed = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "IsFailed"), null));
  if (isFailed.TAG === /* Ok */0) {
    var isSuccess = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "IsSuccess"), null));
    if (isSuccess.TAG === /* Ok */0) {
      var errors = Decco.arrayFromJson(start_open_session_error_reason_t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "Errors"), null));
      if (errors.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  isFailed: isFailed._0,
                  isSuccess: isSuccess._0,
                  errors: errors._0
                }
              };
      }
      var e = errors._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".Errors" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = isSuccess._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".IsSuccess" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isFailed._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".IsFailed" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

exports.file_t_encode = file_t_encode;
exports.file_t_decode = file_t_decode;
exports.files_t_encode = files_t_encode;
exports.files_t_decode = files_t_decode;
exports.download_link_t_encode = download_link_t_encode;
exports.download_link_t_decode = download_link_t_decode;
exports.challenge_key_t_encode = challenge_key_t_encode;
exports.challenge_key_t_decode = challenge_key_t_decode;
exports.session_key_t_encode = session_key_t_encode;
exports.session_key_t_decode = session_key_t_decode;
exports.open_session_t_encode = open_session_t_encode;
exports.open_session_t_decode = open_session_t_decode;
exports.rest_errors_t_encode = rest_errors_t_encode;
exports.rest_errors_t_decode = rest_errors_t_decode;
exports.Exn = Exn;
exports.start_open_session_error_reason_t_encode = start_open_session_error_reason_t_encode;
exports.start_open_session_error_reason_t_decode = start_open_session_error_reason_t_decode;
exports.start_open_session_error_t_encode = start_open_session_error_t_encode;
exports.start_open_session_error_t_decode = start_open_session_error_t_decode;
/* No side effect */
