'use strict';

var API = require("../Rest/API.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function tryStartSession(apiUrl, atveroToken, userEmail, progress) {
  console.log("Effect: start general sharing link session");
  if (atveroToken !== undefined) {
    console.log("Got token, starting open session");
    return API.startSession(apiUrl, atveroToken, progress, Caml_option.some(userEmail), undefined);
  } else {
    console.log("No token: don't start open session");
    return ;
  }
}

function tryVerifyCode(apiUrl, atveroToken, challengeKeyRes, submittedCode, progress) {
  console.log("Effect: verify code");
  if (atveroToken !== undefined) {
    if (typeof challengeKeyRes === "number") {
      console.log("Not verifying code");
      return ;
    }
    if (challengeKeyRes.TAG === /* Success */2) {
      if (submittedCode !== undefined) {
        if (submittedCode === "") {
          console.log("No code to validate");
          return ;
        }
        console.log("Validating code");
        return API.verifyCode(apiUrl, atveroToken, challengeKeyRes._0, submittedCode, progress);
      }
      console.log("No code to validate");
      return ;
    }
    console.log("Not verifying code");
    return ;
  } else {
    console.log("No token: don't verify code");
    return ;
  }
}

function tryLoadFiles(apiUrl, atveroToken, sessionKey, progress) {
  console.log("Effect: load files");
  if (atveroToken !== undefined) {
    if (typeof sessionKey === "number") {
      console.log("Not loading files");
      return ;
    }
    if (sessionKey.TAG === /* Success */2) {
      console.log("Loading files");
      return API.loadFiles(apiUrl, atveroToken, sessionKey._0, progress);
    }
    console.log("Not loading files");
    return ;
  } else {
    console.log("No token: don't load files");
    return ;
  }
}

exports.tryStartSession = tryStartSession;
exports.tryVerifyCode = tryVerifyCode;
exports.tryLoadFiles = tryLoadFiles;
/* API Not a pure module */
