'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Types = require("../Types.bs.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Environment = require("../Bindings/Environment.bs.js");
var DisplayError = require("./DisplayError.bs.js");
var LoginPageLayout = require("./LoginPageLayout.bs.js");

var accentColourBgStyle = {
  backgroundColor: Environment.accentColour
};

var accentColourTextStyle = {
  color: Environment.accentColour
};

var textStyle = {
  color: Environment.fontColour
};

function enterCodeForm(code, update, setCode, isLoading, submitBtnDisabled, errorOccured) {
  return React.createElement("form", {
              onSubmit: (function (e) {
                  e.preventDefault();
                  return Curry._1(update, code);
                })
            }, React.createElement("div", {
                  className: "row "
                }, React.createElement("div", {
                      className: "col-xs-12 form-outline"
                    }, React.createElement("h4", {
                          style: textStyle
                        }, "File Exchange Login"), React.createElement("br", undefined), React.createElement("p", {
                          style: textStyle
                        }, "Confirmation Code email sent, please check your Inbox"), React.createElement("p", {
                          style: textStyle
                        }, "If you haven't received the code within a few minutes of requesting, please check your spam/junk folder."))), errorOccured ? null : React.createElement("div", {
                    className: "row pt-3"
                  }, React.createElement("div", {
                        className: "col-xs-12 form-outline "
                      }, React.createElement("div", {
                            className: "row "
                          }), React.createElement("input", {
                            className: "input-field custom-form-control",
                            disabled: isLoading || errorOccured,
                            placeholder: "Please enter code here",
                            type: "text",
                            value: code,
                            onChange: (function (e) {
                                e.preventDefault();
                                return Curry._1(setCode, e.target.value);
                              })
                          }), React.createElement("input", {
                            className: "btn btn-primary",
                            style: accentColourBgStyle,
                            disabled: submitBtnDisabled,
                            type: "submit",
                            value: "Next"
                          }))));
}

function requestCodeForm(code, update, startSession, errorOccured) {
  return React.createElement("form", {
              onSubmit: (function (e) {
                  e.preventDefault();
                  return Curry._1(update, code);
                })
            }, React.createElement("div", {
                  className: "row "
                }, React.createElement("div", {
                      className: "col-xs-12 form-outline"
                    }, React.createElement("h4", {
                          style: textStyle
                        }, "File Exchange Login"), React.createElement("br", undefined), React.createElement("p", {
                          style: textStyle
                        }, "For security, we will send a confirmation code to the email address this transmittal was sent to."), React.createElement("p", {
                          className: "small-text",
                          style: textStyle
                        }, React.createElement("small", undefined, "Please note, only listed recipients can access this transmittal.")))), errorOccured ? null : React.createElement("div", {
                    className: "row pt-3"
                  }, React.createElement("div", {
                        className: "col-xs-12 form-outline "
                      }, React.createElement("button", {
                            className: "btn request-button btn-primary margin_right_1 btn-float-left",
                            style: accentColourBgStyle,
                            disabled: false,
                            value: "Retry",
                            onClick: (function (e) {
                                e.preventDefault();
                                return Curry._1(startSession, undefined);
                              })
                          }, "Request Code"))));
}

function LogInForm(Props) {
  var update = Props.update;
  var sessionKeyStatus = Props.sessionKeyStatus;
  var atveroToken = Props.atveroToken;
  var challengeKeyStatus = Props.challengeKeyStatus;
  var filesStatus = Props.filesStatus;
  var timeoutErrorMessage = Props.timeoutErrorMessage;
  var startSession = Props.startSession;
  var sessionStarted = Props.sessionStarted;
  var backgroundStyle = {
    backgroundColor: Environment.backgroundColour
  };
  var match = React.useState(function () {
        return "";
      });
  var code = match[0];
  var isLoading = (
    typeof sessionKeyStatus === "number" ? sessionKeyStatus !== 0 : false
  ) || (
    typeof challengeKeyStatus === "number" ? challengeKeyStatus !== 0 : false
  );
  var regularErrorMessage;
  if (atveroToken !== undefined) {
    if (typeof challengeKeyStatus === "number") {
      regularErrorMessage = undefined;
    } else {
      switch (challengeKeyStatus.TAG | 0) {
        case /* Failure */1 :
            regularErrorMessage = challengeKeyStatus._0;
            break;
        case /* Success */2 :
            if (typeof sessionKeyStatus === "number") {
              regularErrorMessage = undefined;
            } else {
              switch (sessionKeyStatus.TAG | 0) {
                case /* Failure */1 :
                    regularErrorMessage = sessionKeyStatus._0;
                    break;
                case /* Success */2 :
                    if (typeof filesStatus === "number") {
                      regularErrorMessage = undefined;
                    } else {
                      switch (filesStatus.TAG | 0) {
                        case /* Failure */1 :
                            regularErrorMessage = filesStatus._0;
                            break;
                        case /* Success */2 :
                            regularErrorMessage = undefined;
                            break;
                        default:
                          regularErrorMessage = undefined;
                      }
                    }
                    break;
                default:
                  regularErrorMessage = undefined;
              }
            }
            break;
        default:
          regularErrorMessage = undefined;
      }
    }
  } else {
    regularErrorMessage = {
      RE_EXN_ID: Types.Exn.NoTokenSpecified
    };
  }
  var submitBtnDisabled = code.length === 0 || isLoading;
  return React.createElement(LoginPageLayout.make, {
              children: React.createElement("div", {
                    className: "row align-items-end login-form",
                    style: backgroundStyle
                  }, isLoading ? React.createElement("div", {
                          className: "row "
                        }, React.createElement("div", {
                              className: "col-xs-12 form-outline"
                            }, React.createElement("div", {
                                  className: "spinner-border ",
                                  role: "status",
                                  style: accentColourTextStyle
                                }))) : (
                      sessionStarted ? enterCodeForm(code, update, match[1], isLoading, submitBtnDisabled, Belt_Option.isSome(timeoutErrorMessage) || Belt_Option.isSome(regularErrorMessage)) : requestCodeForm(code, update, startSession, Belt_Option.isSome(timeoutErrorMessage) || Belt_Option.isSome(regularErrorMessage))
                    ), React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-xs"
                          }, regularErrorMessage !== undefined ? React.createElement(DisplayError.make, {
                                  exn: regularErrorMessage
                                }) : null)), React.createElement("div", {
                        className: "row"
                      }, React.createElement("div", {
                            className: "col-xs"
                          }, timeoutErrorMessage !== undefined ? React.createElement(DisplayError.make, {
                                  exn: timeoutErrorMessage
                                }) : null)))
            });
}

var fontColour = Environment.fontColour;

var accentColour = Environment.accentColour;

var make = LogInForm;

exports.fontColour = fontColour;
exports.accentColour = accentColour;
exports.accentColourBgStyle = accentColourBgStyle;
exports.accentColourTextStyle = accentColourTextStyle;
exports.textStyle = textStyle;
exports.enterCodeForm = enterCodeForm;
exports.requestCodeForm = requestCodeForm;
exports.make = make;
/* react Not a pure module */
