'use strict';

var API = require("../Rest/API.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../Utils/Utils.bs.js");
var React = require("react");
var Images = require("../Images/Images.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Environment = require("../Bindings/Environment.bs.js");

function DisplayFiles(Props) {
  var apiUrl = Props.apiUrl;
  var atveroToken = Props.atveroToken;
  var filenames = Props.filenames;
  var sessionKeyStatus = Props.sessionKeyStatus;
  var downloadAllAsZip = Props.downloadAllAsZip;
  var isDownloading = Props.isDownloading;
  var isZipping = Props.isZipping;
  var setTimeoutErrorMessage = Props.setTimeoutErrorMessage;
  var clientBanner = Environment.clientBanner(Images.atveroBanner);
  var yourFilesText = "Your Files" + (
    isZipping ? " - Zipping" : (
        isDownloading ? " - Downloading" : ""
      )
  );
  var backgroundStyle = {
    backgroundColor: Environment.backgroundColour
  };
  var transluscentBgStyle = {
    backgroundColor: Environment.transluscentBgColour
  };
  var accentColourBgStyle = {
    backgroundColor: Environment.accentColour
  };
  var accentColourTextStyle = {
    color: Environment.accentColour
  };
  var textStyle = {
    color: Environment.fontColour
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "files-container",
                  style: backgroundStyle
                }, React.createElement("div", {
                      className: "header"
                    }, React.createElement("div", undefined, React.createElement("img", {
                              className: "login-company-banner",
                              src: clientBanner
                            })), React.createElement("h3", {
                          className: "text blue bold",
                          style: accentColourTextStyle
                        }, "CheckPoint"))), React.createElement("div", {
                  className: "display-files",
                  style: transluscentBgStyle
                }, React.createElement("div", {
                      className: "button-wrapper"
                    }, React.createElement("p", {
                          className: "files-header",
                          style: textStyle
                        }, yourFilesText), isZipping || isDownloading ? React.createElement("div", {
                            className: "zip-download-progress spinner-grow",
                            role: "status",
                            style: accentColourBgStyle
                          }) : React.createElement("button", {
                            className: "zip-download btn btn-primary",
                            style: accentColourBgStyle,
                            onClick: (function (e) {
                                e.preventDefault();
                                return Curry._1(downloadAllAsZip, undefined);
                              })
                          }, "Download Zip")), React.createElement("div", {
                      className: "files-list"
                    }, Belt_Array.map(filenames, (function (file) {
                            var tmp;
                            if (file.downloadProgress > 0.0) {
                              var progressBar = file.downloadProgress.toString() + "%";
                              tmp = React.createElement("div", {
                                    className: "progress"
                                  }, React.createElement("div", {
                                        className: "download-progress progress-bar",
                                        role: "progressbar",
                                        style: {
                                          color: Environment.accentColour,
                                          width: progressBar
                                        }
                                      }, file.downloadProgress.toString() + "%"));
                            } else {
                              tmp = React.createElement("img", {
                                    className: "download-arrow",
                                    style: accentColourTextStyle,
                                    src: Images.downloadArrow
                                  });
                            }
                            return React.createElement("div", {
                                        key: file.file.name,
                                        className: "row mb-1 file-download-row",
                                        style: backgroundStyle,
                                        onClick: (function (e) {
                                            e.preventDefault();
                                            API.getFileDownloadLink(apiUrl, atveroToken, sessionKeyStatus, file.file.name, (function (downloadUrlStatus) {
                                                    if (typeof downloadUrlStatus === "number") {
                                                      return ;
                                                    }
                                                    switch (downloadUrlStatus.TAG | 0) {
                                                      case /* Failure */1 :
                                                          var err = downloadUrlStatus._0;
                                                          console.log("Error getting download link: ", err);
                                                          return Curry._1(setTimeoutErrorMessage, err);
                                                      case /* Success */2 :
                                                          return Curry._1(Utils.open_, downloadUrlStatus._0);
                                                      default:
                                                        return ;
                                                    }
                                                  }));
                                            
                                          })
                                      }, React.createElement("div", {
                                            className: "col-sm-9 file-name"
                                          }, React.createElement("p", {
                                                style: textStyle
                                              }, file.file.name)), React.createElement("div", {
                                            className: "col-sm-3 pull-right"
                                          }, tmp));
                          })))));
}

var make = DisplayFiles;

exports.make = make;
/* API Not a pure module */
