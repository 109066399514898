'use strict';

var Types = require("../Types.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var open_ = window.open;

function logArrayWithindex(msgs) {
  return Belt_Array.forEachWithIndex(msgs, (function (i, m) {
                console.log(String(i + 1 | 0) + ": " + m);
                
              }));
}

function getServerExn(arg) {
  if (arg === Types.Exn.noTokenSpecifiedConst) {
    return {
            RE_EXN_ID: Types.Exn.NoTokenSpecified
          };
  } else if (arg === Types.Exn.noSessionSpecifiedConst) {
    return {
            RE_EXN_ID: Types.Exn.NoSessionSpecified
          };
  } else if (arg === Types.Exn.noFileSpecifiedConst) {
    return {
            RE_EXN_ID: Types.Exn.NoFileSpecified
          };
  } else if (arg === Types.Exn.noChallengeSpecifiedConst) {
    return {
            RE_EXN_ID: Types.Exn.NoChallengeSpecified
          };
  } else if (arg === Types.Exn.noCodeSpecifiedConst) {
    return {
            RE_EXN_ID: Types.Exn.NoCodeSpecified
          };
  } else if (arg === Types.Exn.invalidSessionConst) {
    return {
            RE_EXN_ID: Types.Exn.InvalidSession
          };
  } else if (arg === Types.Exn.expiredTokenConst) {
    return {
            RE_EXN_ID: Types.Exn.ExpiredToken
          };
  } else if (arg === Types.Exn.invalidTokenConst) {
    return {
            RE_EXN_ID: Types.Exn.InvalidToken
          };
  } else {
    return {
            RE_EXN_ID: Types.Exn.UnknownError,
            _1: arg
          };
  }
}

function getFromQueryString(key) {
  var rawOpts = window.location.search;
  return Caml_option.nullable_to_opt(new URLSearchParams(rawOpts).get(key));
}

function getFromRouterUrl(url, key) {
  var rawOpts = url.search;
  return Caml_option.nullable_to_opt(new URLSearchParams(rawOpts).get(key));
}

exports.open_ = open_;
exports.logArrayWithindex = logArrayWithindex;
exports.getServerExn = getServerExn;
exports.getFromQueryString = getFromQueryString;
exports.getFromRouterUrl = getFromRouterUrl;
/* open_ Not a pure module */
