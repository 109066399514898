'use strict';

var Images = require("../Images/Images.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var apiUrl = Belt_Option.getWithDefault((process.env.AZURE_API_URL == null) ? undefined : Caml_option.some(process.env.AZURE_API_URL), "https://atveroexchangedev.azurewebsites.net");

var image = process.env.BACKGROUND_IMAGE;

var backgroundImageName = (image == null) ? Images.atveroBackground : (
    image.startsWith("https://") ? image : "https://atveroexchangeimages.atvero.com/" + image
  );

function clientTitleText(fallback) {
  return Belt_Option.getWithDefault((process.env.CLIENT_TITLE_TEXT == null) ? undefined : Caml_option.some(process.env.CLIENT_TITLE_TEXT), fallback);
}

function clientLogo(fallBack) {
  return Belt_Option.getWithDefault((process.env.CLIENT_LOGO == null) ? undefined : Caml_option.some(process.env.CLIENT_LOGO), fallBack);
}

function clientBanner(fallBack) {
  return Belt_Option.getWithDefault((process.env.CLIENT_BANNER == null) ? undefined : Caml_option.some(process.env.CLIENT_BANNER), fallBack);
}

var backgroundColour = Belt_Option.getWithDefault((process.env.BACKGROUND_COLOUR == null) ? undefined : Caml_option.some(process.env.BACKGROUND_COLOUR), "#c9cfd3");

var transluscentBgColour = Belt_Option.getWithDefault((process.env.TRANSPARENT_BACKGROUND_COLOUR == null) ? undefined : Caml_option.some(process.env.TRANSPARENT_BACKGROUND_COLOUR), "rgba(222, 236, 249, 0.8)");

var accentColour = Belt_Option.getWithDefault((process.env.ACCENT_COLOUR == null) ? undefined : Caml_option.some(process.env.ACCENT_COLOUR), "#0078d5");

var fontColour = Belt_Option.getWithDefault((process.env.FONT_COLOUR == null) ? undefined : Caml_option.some(process.env.FONT_COLOUR), "#323130");

exports.apiUrl = apiUrl;
exports.backgroundImageName = backgroundImageName;
exports.clientTitleText = clientTitleText;
exports.clientLogo = clientLogo;
exports.clientBanner = clientBanner;
exports.backgroundColour = backgroundColour;
exports.transluscentBgColour = transluscentBgColour;
exports.accentColour = accentColour;
exports.fontColour = fontColour;
/* apiUrl Not a pure module */
