'use strict';

var LogoOnBlueSvg = require("./LogoOnBlue.svg");
var AtveroBannerSvg = require("./atveroBanner.svg");
var Atv_backgroundJpg = require("./atv_background.jpg");
var DownloadArrowSvg = require("./download-arrow.svg");
var PoweredByAtveroSvg = require("./poweredByAtvero.svg");

var atveroLogo = LogoOnBlueSvg;

var downloadArrow = DownloadArrowSvg;

var poweredByAtveroLogo = PoweredByAtveroSvg;

var atveroBanner = AtveroBannerSvg;

var atveroBackground = Atv_backgroundJpg;

exports.atveroLogo = atveroLogo;
exports.downloadArrow = downloadArrow;
exports.poweredByAtveroLogo = poweredByAtveroLogo;
exports.atveroBanner = atveroBanner;
exports.atveroBackground = atveroBackground;
/* atveroLogo Not a pure module */
