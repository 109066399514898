'use strict';

var Types = require("../Types.bs.js");
var React = require("react");
var DisplayError = require("./DisplayError.bs.js");
var LoginPageLayout = require("./LoginPageLayout.bs.js");

function UnexpectedUrl(Props) {
  return React.createElement("div", {
              className: "login-display row"
            }, React.createElement(LoginPageLayout.make, {
                  children: React.createElement(DisplayError.make, {
                        exn: {
                          RE_EXN_ID: Types.Exn.UnexpectedUrl
                        }
                      })
                }));
}

var make = UnexpectedUrl;

exports.make = make;
/* react Not a pure module */
