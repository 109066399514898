'use strict';

var React = require("react");
var Router = require("./Router.bs.js");
var ReactDom = require("react-dom");
var Environment = require("./Bindings/Environment.bs.js");

((require("../scss/index.scss")));

ReactDom.render(React.createElement(Router.make, {
          apiUrl: Environment.apiUrl
        }), document.body);

/*  Not a pure module */
