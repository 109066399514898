'use strict';

var API = require("../Rest/API.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Images = require("../Images/Images.bs.js");
var LogInForm = require("../Components/LogInForm.bs.js");
var AppEffects = require("../Effects/AppEffects.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Environment = require("../Bindings/Environment.bs.js");
var DisplayError = require("../Components/DisplayError.bs.js");
var DisplayFiles = require("../Components/DisplayFiles.bs.js");
var LoginPageLayout = require("../Components/LoginPageLayout.bs.js");

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetIsDownloading */0 :
        return {
                isDownloading: action._0,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: state.code,
                challengeKeyStatus: state.challengeKeyStatus,
                sessionKeyStatus: state.sessionKeyStatus,
                filesStatus: state.filesStatus,
                timeoutErrorMessage: state.timeoutErrorMessage,
                sessionStarted: state.sessionStarted
              };
    case /* SetIsZipping */1 :
        return {
                isDownloading: state.isDownloading,
                isZipping: action._0,
                atveroToken: state.atveroToken,
                code: state.code,
                challengeKeyStatus: state.challengeKeyStatus,
                sessionKeyStatus: state.sessionKeyStatus,
                filesStatus: state.filesStatus,
                timeoutErrorMessage: state.timeoutErrorMessage,
                sessionStarted: state.sessionStarted
              };
    case /* SetFilesStatus */2 :
        return {
                isDownloading: state.isDownloading,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: state.code,
                challengeKeyStatus: state.challengeKeyStatus,
                sessionKeyStatus: state.sessionKeyStatus,
                filesStatus: action._0,
                timeoutErrorMessage: state.timeoutErrorMessage,
                sessionStarted: state.sessionStarted
              };
    case /* SetCode */3 :
        return {
                isDownloading: state.isDownloading,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: action._0,
                challengeKeyStatus: state.challengeKeyStatus,
                sessionKeyStatus: state.sessionKeyStatus,
                filesStatus: state.filesStatus,
                timeoutErrorMessage: state.timeoutErrorMessage,
                sessionStarted: state.sessionStarted
              };
    case /* SetChallengeKeyStatus */4 :
        return {
                isDownloading: state.isDownloading,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: state.code,
                challengeKeyStatus: action._0,
                sessionKeyStatus: state.sessionKeyStatus,
                filesStatus: state.filesStatus,
                timeoutErrorMessage: undefined,
                sessionStarted: true
              };
    case /* SetSessionKeyStatus */5 :
        return {
                isDownloading: state.isDownloading,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: state.code,
                challengeKeyStatus: state.challengeKeyStatus,
                sessionKeyStatus: action._0,
                filesStatus: state.filesStatus,
                timeoutErrorMessage: state.timeoutErrorMessage,
                sessionStarted: state.sessionStarted
              };
    case /* UpdateFile */6 :
        var update = action._0;
        var filesStatus = state.filesStatus;
        var modFilesStatus;
        modFilesStatus = typeof filesStatus === "number" || filesStatus.TAG !== /* Success */2 ? state.filesStatus : ({
              TAG: /* Success */2,
              _0: Belt_Array.map(filesStatus._0, (function (filename) {
                      if (filename.file.name === update.file.name) {
                        return update;
                      } else {
                        return filename;
                      }
                    }))
            });
        return {
                isDownloading: state.isDownloading,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: state.code,
                challengeKeyStatus: state.challengeKeyStatus,
                sessionKeyStatus: state.sessionKeyStatus,
                filesStatus: modFilesStatus,
                timeoutErrorMessage: state.timeoutErrorMessage,
                sessionStarted: state.sessionStarted
              };
    case /* SetTimeoutErrorMessage */7 :
        return {
                isDownloading: state.isDownloading,
                isZipping: state.isZipping,
                atveroToken: state.atveroToken,
                code: undefined,
                challengeKeyStatus: /* NotSent */0,
                sessionKeyStatus: /* NotSent */0,
                filesStatus: /* NotSent */0,
                timeoutErrorMessage: action._0,
                sessionStarted: state.sessionStarted
              };
    
  }
}

function initialState(initialAtveroToken, initialSessionKey) {
  return {
          isDownloading: false,
          isZipping: false,
          atveroToken: initialAtveroToken,
          code: undefined,
          challengeKeyStatus: /* NotSent */0,
          sessionKeyStatus: initialSessionKey !== undefined ? ({
                TAG: /* Success */2,
                _0: initialSessionKey
              }) : /* NotSent */0,
          filesStatus: /* NotSent */0,
          timeoutErrorMessage: undefined,
          sessionStarted: false
        };
}

function FilesApp(Props) {
  var apiUrl = Props.apiUrl;
  var initialSessionKey = Props.initialSessionKey;
  var initialAtveroToken = Props.initialAtveroToken;
  var initialUserEmail = Props.initialUserEmail;
  var skipUserBtnPress = Props.skipUserBtnPress;
  var match = React.useReducer(reducer, initialState(initialAtveroToken, initialSessionKey));
  var dispatch = match[1];
  var state = match[0];
  var hasSuppliedSessionKey = Belt_Option.isSome(initialSessionKey);
  var startSession = function (param) {
    AppEffects.tryStartSession(apiUrl, state.atveroToken, initialUserEmail, (function (res) {
            return Curry._1(dispatch, {
                        TAG: /* SetChallengeKeyStatus */4,
                        _0: res
                      });
          }));
    
  };
  React.useEffect((function () {
          var match = state.sessionKeyStatus;
          if (typeof match === "number" || match.TAG !== /* Success */2) {
            return ;
          } else {
            return AppEffects.tryLoadFiles(apiUrl, state.atveroToken, state.sessionKeyStatus, (function (res) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetFilesStatus */2,
                                      _0: res
                                    });
                        }));
          }
        }), [state.sessionKeyStatus]);
  React.useEffect((function () {
          if (hasSuppliedSessionKey) {
            return ;
          } else {
            return AppEffects.tryVerifyCode(apiUrl, state.atveroToken, state.challengeKeyStatus, state.code, (function (res) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSessionKeyStatus */5,
                                      _0: res
                                    });
                        }));
          }
        }), [state.code]);
  React.useEffect((function () {
          if (skipUserBtnPress) {
            return AppEffects.tryStartSession(apiUrl, state.atveroToken, initialUserEmail, (function (res) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetChallengeKeyStatus */4,
                                      _0: res
                                    });
                        }));
          }
          
        }), []);
  var match$1 = state.filesStatus;
  var displayingFiles;
  displayingFiles = typeof match$1 === "number" ? (
      match$1 === /* NotSent */0 ? false : true
    ) : (
      match$1.TAG === /* Downloading */0 ? false : true
    );
  var accentColourTextStyle = {
    color: Environment.accentColour
  };
  var tmp;
  if (displayingFiles) {
    var atveroToken = Belt_Option.getWithDefault(state.atveroToken, "");
    var filenames = state.filesStatus;
    var fileNames;
    fileNames = typeof filenames === "number" ? [] : (
        filenames.TAG === /* Success */2 ? filenames._0 : []
      );
    var match$2 = state.filesStatus;
    var tmp$1;
    var exit = 0;
    if (typeof match$2 === "number") {
      tmp$1 = null;
    } else {
      switch (match$2.TAG | 0) {
        case /* Downloading */0 :
        case /* Success */2 :
            exit = 1;
            break;
        default:
          tmp$1 = null;
      }
    }
    if (exit === 1) {
      tmp$1 = React.createElement(DisplayFiles.make, {
            apiUrl: apiUrl,
            atveroToken: atveroToken,
            filenames: fileNames,
            sessionKeyStatus: state.sessionKeyStatus,
            downloadAllAsZip: (function (param) {
                API.getZipDownload(apiUrl, atveroToken, state.sessionKeyStatus, (function (param) {
                        
                      }));
                
              }),
            isDownloading: state.isDownloading,
            isZipping: state.isZipping,
            setTimeoutErrorMessage: (function (exnOpt) {
                return Curry._1(dispatch, {
                            TAG: /* SetTimeoutErrorMessage */7,
                            _0: exnOpt
                          });
              })
          });
    }
    tmp = React.createElement("div", {
          className: "display"
        }, React.createElement("div", undefined, React.createElement("img", {
                  className: "atvero-logo",
                  src: Images.poweredByAtveroLogo
                })), tmp$1);
  } else if (hasSuppliedSessionKey) {
    var exn = state.filesStatus;
    var exit$1 = 0;
    if (typeof exn === "number" || exn.TAG !== /* Failure */1) {
      exit$1 = 1;
    } else {
      tmp = React.createElement("div", {
            className: "login-display row"
          }, React.createElement(LoginPageLayout.make, {
                children: React.createElement(DisplayError.make, {
                      exn: exn._0
                    })
              }));
    }
    if (exit$1 === 1) {
      tmp = React.createElement("div", {
            className: "login-display row"
          }, React.createElement(LoginPageLayout.make, {
                children: React.createElement("div", {
                      className: "row pt-3"
                    }, React.createElement("div", {
                          className: "col-xs-12 form-outline "
                        }, React.createElement("div", {
                              className: "spinner-border ",
                              role: "status",
                              style: accentColourTextStyle
                            })))
              }));
    }
    
  } else {
    tmp = React.createElement("div", {
          className: "login-display row"
        }, React.createElement(LogInForm.make, {
              update: (function (arg) {
                  return Curry._1(dispatch, {
                              TAG: /* SetCode */3,
                              _0: arg
                            });
                }),
              sessionKeyStatus: state.sessionKeyStatus,
              atveroToken: state.atveroToken,
              challengeKeyStatus: state.challengeKeyStatus,
              filesStatus: state.filesStatus,
              timeoutErrorMessage: state.timeoutErrorMessage,
              startSession: startSession,
              sessionStarted: state.sessionStarted
            }));
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement("div", {
                  className: "stonly-widget-wrapper",
                  id: "stonly-widget"
                }));
}

var make = FilesApp;

exports.reducer = reducer;
exports.initialState = initialState;
exports.make = make;
/* API Not a pure module */
